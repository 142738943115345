// APP JS
import AOS from 'aos';
AOS.init({
  // Global settings:
  disable: false,
  // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded',
  // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init',
  // class applied after initialization
  animatedClassName: 'aos-animate',
  // class applied on animation
  useClassNames: false,
  // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false,
  // disables automatic mutations' detections (advanced)
  debounceDelay: 50,
  // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99,
  // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120,
  // offset (in px) from the original trigger point
  delay: 0,
  // values from 0 to 3000, with step 50ms
  duration: 1000,
  // values from 0 to 3000, with step 50ms
  easing: 'ease-out-back',
  // default easing for AOS animations
  once: false,
  // whether animation should happen only once - while scrolling down
  mirror: false,
  // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom' // defines which position of the element regarding to window should trigger the animation
});

(function ($) {
  $(window).load(function () {

    // owl-carousel
    $('.owl-carousel:not(.slider)').owlCarousel({
      items: 1,
      loop: true,
      lazyLoad: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 10000,

      // speed
      smartSpeed: 500,

      // animation
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      dots: true,

      // drag
      mouseDrag: false
    })

    // slider
    $('.slider:not(.work-slider)').owlCarousel({
      items: 1,
      margin: 15,
      loop: true,
      lazyLoad: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 1
        },
        768: {
          items: 2
        },
        992: {
          items: 2
        },
        1200: {
          items: 2
        },
        1400: {
          items: 2,
          margin: 80
        }
      }
    });

    // work-slider
    $('.work-slider').owlCarousel({
      items: 2,
      margin: 20,
      loop: true,
      autoWidth:true,
      lazyLoad: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: false,
      dots: false,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        1200: {
          margin: 40
        }
      }
    });

    // form-sent
    setTimeout(() => {

      var locationSearch = '' + document.location.search;
      if (
        (-1 === locationSearch.indexOf('form[sent]') && -1 === decodeURIComponent(locationSearch).indexOf('form[sent]'))
        || -1 === document.location.hash.indexOf('#form')
      ) {
        return;
      }

      var $headerHeight = $('.header');
      var $message = $('.form-success-message');

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $('html').scrollTop();
      var newScrollTop = $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $('html, body').animate({ scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30 });
    }, 500);

  });

  $(document).ready(function () {

    // get a reference to the logo element
    const logo = document.querySelector('.logo');

    // get a reference to the section with the js-white-bg class
    const whiteBgSection = document.querySelector('.js-white-bg');

    // listen for the window scroll event
    window.addEventListener('scroll', () => {
      // get the current scroll position
      const scrollPosition = window.scrollY;

      // get the top position of the whiteBgSection element
      const whiteBgTopPosition = whiteBgSection.offsetTop;

      // if the scroll position is greater than or equal to the top position of the whiteBgSection element
      if (scrollPosition >= whiteBgTopPosition) {
        // add the logo-color class and remove the logo-white class from the logo element
        logo.classList.add('logo-color');
        logo.classList.remove('logo-white');
        logo.querySelector('img').src = 'images/logo-color.svg';
      } else {
        // add the logo-white class and remove the logo-color class from the logo element
        logo.classList.add('logo-white');
        logo.classList.remove('logo-color');
        logo.querySelector('img').src = 'images/logo-white.svg';
      }
    });

    // menu-overlay
    $('.menu-overlay').on('show.bs.collapse', function () {
      $(this).addClass("show");
    });

    // sticky
    //require('../../vendor/w3media/framework/assets/js/V2/body-sticky-class');
    $(window).scroll(function () {
      var windowWidth = $(window).width();
      var $el = $('body').add('.header');
      if (windowWidth < 0) {
        $el.removeClass('sticky');
        return;
      }

      if ($(window).scrollTop() > 0) {
        $el.addClass('sticky');
      } else {
        $el.removeClass('sticky');
      }
    });

    // fancybox
    $('a[data-fancybox]').fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });

    // scroll-to-content
    $('.js-3w-scroll-to-content').on('click', function (event) {
      event.preventDefault();
      var identifier = $(this).attr('data-target');
      var $element = $(identifier);

      if ($element) {
        $('html, body').animate({ scrollTop: $element.offset().top }, 500);
      }
    });
  });
})(jQuery);
